export function setAds() {
  const _billboard = document.getElementsByClassName('default-billboard');
  const _sticky1 = document.getElementsByClassName('default-sticky-1');
  if (_billboard[0]) {
    if (!_billboard[0].hasChildNodes()) {
      console.warn('_billboard', _billboard, _billboard[0].hasChildNodes());
      _billboard[0].setAttribute('style', 'min-height: 0px !important;');
    }
  }
  if (_sticky1[0]) {
    if (!_sticky1[0].hasChildNodes()) {
      console.warn('_sticky1', _sticky1, _sticky1[0].hasChildNodes());
      _sticky1[0].setAttribute('style', 'min-height: 0px !important;');
    }
  }
}
