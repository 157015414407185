import _ from 'lodash';
import Image from 'src/components/image';
import { APP_IMG, NEXT_DAILYMOTION_ID } from 'src/constants';
import styled from 'styled-components';
// import styled from 'styled-components';

export const getFeatureImage = (_data, _showImg = false) => {
  if (_.isEmpty(_data)) return null;
  if (_showImg) {
    return <Image image={_data?.image} title={_data?.title} ignoreLazy={false} type={'FEATURE_IMAGE'} />;
  }
  switch (_data?.type_video) {
    case '0':
      return (
        <div className='embed-responsive embed-responsive-16by9'>
          <iframe src={`${`${APP_IMG}/${_data?.video_src}`}?autoplay=1`} />
        </div>
      );
    case '1': // dailymotion
      return (
        <EmbdedWrapper>
          <div className='embed-dailymotion'>
            <div
              // className='embed-dailymotion'
              dangerouslySetInnerHTML={{
                __html: `
              <script
              defer
              type='text/javascript'
              src='${NEXT_DAILYMOTION_ID}'
              data-video=${_data?.video_src}
              ></script>`
              }}
            />
          </div>
        </EmbdedWrapper>
      );
    case '2': // youtube url
      return (
        <div className='embed-responsive embed-responsive-16by9'>
          <iframe src={`${_data?.video_src}?autoplay=1`} />
        </div>
      );

    default:
      return <Image image={_data?.image} title={_data?.title} ignoreLazy={false} type={'FEATURE_IMAGE'} />;
  }
};

// const EmbdedWrapper = styled.div`
//   position: absolute;
//   display: block;
//   width: 100%;
//   top: 0;
//   overflow: hidden;
//   .embed-dailymotion {
//     iframe {
//       width: 100%;
//       height: 480px;
//       @media (max-width: 500px) {
//         height: 220px;
//       }
//     }
//   }
// `;

const EmbdedWrapper = styled.div`
  position: relative;
  .embed-dailymotion {
    width: 100%;
    aspect-ratio: 16 / 9;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    .dailymotion-player-root {
      position: unset !important;
    }
    .resolution-video,
    .resolution-dailymotion {
      width: 100%;
      height: 100%;
    }
  }
`;
