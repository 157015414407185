export function setContents() {
  const contents = document.getElementById('contents');

  // const _p = contents?.querySelectorAll('p');
  const _iframe = contents?.querySelectorAll('p iframe');
  const _image = contents?.querySelectorAll('picture img');
  const _picture = contents?.querySelectorAll('p picture');
  const _table = contents?.getElementsByTagName('table');

  if (_image) {
    for (let i = 0; i < _image.length; i++) {
      if (_image[i]) {
        const _height = _image[i].getAttribute('height');
        const _width = _image[i].getAttribute('width');
        const _style = _image[i].getAttribute('style');
        const _newStyle = `
                display: block !important;
                width: 100% !important;
                height: 100% !important;
                margin: auto !important;
                margin-top: 15px;
                max-height: ${_height > 0 && _height !== '100%' ? `${_height}px` : '100%'} !important;
                max-width: ${_width > 0 && _width !== '100%' ? `${_width}px` : '100%'} !important;
              `;
        const _n = _style ? `${_style || ''}${_newStyle}` : _newStyle;
        _image[i].setAttribute('style', _n);

        if (_picture[i]) {
          const _img = _picture[i].getElementsByTagName('img')[0];

          if (_img) {
            const _caption = document.createElement('span');
            const _class = _img.getAttribute('class');
            const _showcaption = _class.search('showcaption-true') !== -1;
            const _width = _img.getAttribute('width');
            if (_caption) {
              _caption.className = 'image-caption';
              _caption.style = `
                      display: none !important;
                      max-width: ${_width > 0 && _width !== '100%' ? `${_width}px` : '100%'} !important;
                      margin: 0 auto !important;
                      text-align: center;`;
              _caption.innerHTML = _img.alt || null;
              if (_showcaption) {
                _picture[i].insertAdjacentElement('beforeend', _caption);
              }
            }
          }
        }
      }
    }
  }

  if (_iframe) {
    for (let i = 0; i < _iframe.length; i++) {
      if (_iframe[i]) {
        const _width = _iframe[i].getAttribute('width');
        const _height = _iframe[i].getAttribute('height');
        _iframe[i].setAttribute(
          'style',
          `
                  display: block !important;
                  position: relative !important;
                  margin: 15px auto !important;
                  width: 100% !important;
                  height: 100% !important;
                  min-height: ${_height > 0 && _height !== '100%' ? `${_height}px` : '480px'} !important;
                  max-width: ${_width > 0 && _width !== '100%' ? `${_width}px` : '100%'} !important;
                  max-height: ${_height > 0 && _height !== '100%' ? `${_height}px` : '100%'} !important;
                `
        );
      }
    }
  }

  // scroll table
  if (_table) {
    for (let i = 0; i < _table.length; i++) {
      if (_table[i]) {
        const _div = document.createElement('div');
        _div.setAttribute('style', 'overflow-x:auto;');
        const _TableScroll = _table[i].insertAdjacentElement('beforebegin', _div);
        // retrun
        _table[i].setAttribute('style', 'width:540px; margin: auto;');
        _TableScroll.append(_table[i]);
      }
    }
  }
}
