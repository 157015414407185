import Carousel from 'react-slick';
import { APP_IMG } from 'src/constants';
import _ from 'lodash';
import styled from 'styled-components';
const SectionAffiliate = ({ position, data }) => {
  if (_.isEmpty(data)) return null;
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000
  };
  const _position = position;
  const data2 = data.filter(item => item.position === _position);
  if (_.isEmpty(data2)) return null;

  return (
    <>
      <ShowDesktop className='section-affiliate'>
        {
          <Carousel {...settings}>
            {!_.isEmpty(data2) &&
              data2
                .filter(data => data?.image !== null)
                .map((item, index) => (
                  <div key={index} className='banner-special'>
                    <a tabIndex='-1' href={!item?.link ? '#' : item.link} target={!item?.title ? '_self' : '_blank'} rel={!item?.title ? '' : 'noopener noreferrer nofollow'}>
                      <img src={`${APP_IMG}${item?.image}`} alt={item?.title} width='100%' height='100%' />
                    </a>
                  </div>
                ))}
          </Carousel>
        }
      </ShowDesktop>
      <ShowMobile className='section-affiliate'>
        {
          <Carousel {...settings}>
            {!_.isEmpty(data2) &&
              data2
                .filter(data => data?.image !== null)
                .map((item, index) => (
                  <div key={index} className='banner-special'>
                    <a tabIndex='-1' href={!item?.link ? '#' : item?.link} target={!item?.title ? '_self' : '_blank'} rel={!item?.title ? '' : 'noopener noreferrer nofollow'}>
                      <img src={`${APP_IMG}${item?.image}`} alt={item?.title} width='100%' height='100%' />
                    </a>
                  </div>
                ))}
          </Carousel>
        }
      </ShowMobile>
    </>
  );
};

const ShowDesktop = styled.div`
  display: block;
  width: 100%;
  height: auto;
  @media (max-width: 767px) {
    display: none;
    width: 100%;
  }
`;
const ShowMobile = styled.div`
  display: none;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    overflow-x: hidden;
    height: auto;

    .slick-slider {
      position: relative;
      img {
        pointer-events: none;
      }
    }
  }
`;
export default SectionAffiliate;
