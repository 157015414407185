import Carousel from 'react-slick';

// TODO : <Carousels> ... </Carousels>

const Carousels = ({ infinite, slideShow = 1, responsiveShow, autoPlay = false, showDots = false, heading, children }) => {
  const settings = {
    dots: showDots,
    infinite: true,
    slidesToShow: slideShow,
    slidesToScroll: 1,
    autoplay: autoPlay,
    autoplaySpeed: 3000,
    speed: 800,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: slideShow,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: responsiveShow || 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: responsiveShow || 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: responsiveShow || 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <div className='carousel-wrapper'>
        {heading && <div className='heading'> {heading} </div>}
        <Carousel aria-hidden='true' {...settings}>
          {children}
        </Carousel>
      </div>
    </>
  );
};

export default Carousels;
